<template>
  <section v-if="data.activateBookingTool">
    <iframe
      id="justbook"
      class="w-full"
      :src="`https://justbooknow.waldhaus-sils.ch/justbook/?sprache=${localeNumber}&p=0&k=2`"
      scrolling="no"
      style="display: block; width: 100%; border: none"
    >
    </iframe>
  </section>
</template>

<script setup>
import { onMounted } from 'vue';
import { useHead } from '#imports';

const iframe = ref(null);
const { locale } = useI18n();

const props = defineProps({
  data: Object
});

const localeNumber = computed(() => {
  switch (locale.value) {
    case 'de':
      return 1;
    case 'en':
      return 2;
    case 'fr':
      return 3;
    case 'it':
      return 4;
    default:
      return 1; // default case if the locale is none of the above
  }
});

// Use `useHead` to inject the script tag into the <head> of the document
useHead({
  script: [
    {
      src: 'https://waldhaus.justbooknow.ch/plugins/iframeResizer.min.js',
      defer: true
    }
  ]
});

const onResize = () => {
  if (iframe.value && window.iFrameResize) {
    window.iFrameResize({}, '#justbook');
  }
};

onMounted(() => {
  window.addEventListener('resize', onResize);
  // Ensure the script has loaded and the document is ready
  iframe.value = document.getElementById('justbook');
  nextTick(() => {
    setTimeout(function () {
      window.iFrameResize({}, '#justbook');
    }, 2000);
  });
});
</script>
