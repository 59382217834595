export default function useGetPageBaseUrl(
  isHome: Boolean = false,
  url: String,
  __i18n_lang: String
) {
  let config = useRuntimeConfig();
  const appLocales = config.public.locales;
  const defaultLocale = config.public.defaultLocale;
  const baseUrl = config.public.baseURL;

  const locale = appLocales.find((l) => __i18n_lang === l.code);
  const isDefaultLocale = defaultLocale === locale?.code;

  // Note: for base Language, we have this workaround
  // to avoid having Url like 'http://localhost:3333//'
  const pageLocaleSlug = isDefaultLocale ? '' : `/${locale?.code}`;
  const pageSlug = isHome ? '' : url?.fullPath || '';

  return `${baseUrl}${pageLocaleSlug}${pageSlug}`;
}
