import { EventApiPage, SanityPage } from '~/types';

export default function useEventsJsonLdMeta(page: EventApiPage) {
  const { t, locale } = useI18n();
  if (!page) return;

  // The pageUrl is the full path to the page, including the locale. If the page is the home page, pageUrl will be an empty string.
  const pageUrl = page.url ? page.url?.fullPath : '';

  // The page's title. If the page doesn't have a title, it will be the default website title.
  const name = page.seoMetadata?.title || t('seo.websiteTitle');

  // The page's description. If the page doesn't have a description, it will be the default description.
  const description = page.seoMetadata?.description || t('seo.description');

  // The page's full URL. If the page is the home page, url will be the base URL.
  const url = useGetPageBaseUrl(false, pageUrl, locale.value);

  // The base metadata for the page.
  const baseMeta = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name,
    description,
    url
  });

  // Add the base metadata to the document's head.
  useHead({
    script: {
      type: 'application/ld-json',
      children: baseMeta
    }
  });
}
