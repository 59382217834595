import i18nConfig from '../i18ncustom.config.js';

/**
 * In this query, we retrieve a page and its translations
 */

const isPageHome = (fullPath: string) => {
  if (fullPath === '/') {
    return true;
  }

  if (fullPath.length <= 3) {
    return i18nConfig.locales.some((l) => fullPath.includes(l.code));
  }

  return false;
};

const removeLanguageCode = (fullPath: string, locale: string) => {
  // if it is baseLanguage, we don't have the locale in
  // the url and we have to remove just the "/" string
  if (locale === i18nConfig.locales[0].code) {
    return fullPath.slice(1);
  }

  return fullPath.replace(`/${locale}/`, '');
};

export default function getPageQuery(fullPath: string, locale: string) {
  let query;
  if (isPageHome(fullPath)) {
    query = `_type=="page" && live==true && isHome==true &&
    __i18n_lang=="${locale}" && !(_id in path("drafts.**"))`;
  } else {
    // Query for all pages
    query = `live==true && url.fullPath=="${removeLanguageCode(fullPath, locale)}"
    && __i18n_lang=="${locale}" && !(_id in path("drafts.**"))`;
  }

  return groq`*[${query}][0] {
  ...,
  parent->{slug},
  content{
    ...,
    sections[] {
      ...,
      _type == 'downloadList' => {
        ...,
        list[] {
          type == 'file' => { 'file': file.asset->{extension, originalFilename, url}, type, name},
          type == 'image' => { 'picture': picture.refImage->{ 'upload': upload.asset->{url, originalFilename}}, type, name, 'ref': @ },
        }
      },
      _type == 'carousel' => {
        all_carouselList[]->{detailPageSettings, _id}
      },
      _type == 'carousel' && documentType == 'activity' => {
        activity_carouselList[]->{detailPageSettings, _id}
      },
      _type == 'carousel' && documentType == 'package' => {
        package_carouselList[]->{detailPageSettings, _id}
      },
      _type == 'carousel' && documentType == 'restaurant' => {
        restaurant_carouselList[]->{detailPageSettings, _id}
      },
      _type == 'carousel' && documentType == 'room' => {
        room_carouselList[]->{detailPageSettings, _id}
      },
      _type == 'carousel' && documentType == 'page' => {
        page_carouselList[]->{detailPageSettings, _id}
      },
    }
  },
  !(_id match "__i18n_") => {
    "translations": {
      "de": {
        isHome,
        url
      },
      "fr":__i18n_refs[_key == "fr"][0]->{
        isHome,
        url
      },
      "en":__i18n_refs[_key == "en"][0]->{
        isHome,
        url
      },
      "it":__i18n_refs[_key == "it"][0]->{
        isHome,
        url
      },
    }
  },
  (_id match "*__i18n_*") => {
    "translations":
    *[references(^._id) && __i18n_lang =="de"]{
      "de":{
        isHome,
        url,
        __i18n_lang,
      },
      "fr":(__i18n_refs[]->{
        isHome,
        url,
        __i18n_lang,
      })[__i18n_lang=="fr"][0], 
      "en":(__i18n_refs[]->{
        isHome,
        url,
        __i18n_lang,
      })[__i18n_lang=="en"][0], 
      "it":(__i18n_refs[]->{
        isHome,
        url,
        __i18n_lang,
      })[__i18n_lang=="it"][0], 
    }[0]
  }
}`;
}
