import imageUrlBuilder from '@sanity/image-url';
import { EventApiPage, SanityPage } from '~/types';

export default function useSEOEventsMetatags(page: EventApiPage | null) {
  const { t } = useI18n();
  if (!page || !page?.seoMetadata) return;

  // GET OG IMAGE
  let config = useRuntimeConfig();
  const builder = imageUrlBuilder(config.public.sanity);
  const ogImage = page?.seoMetadata?.image
    ? page?.seoMetadata?.image
    : `${config.public.baseURL}ogimage.jpg`;

  const pageTitle = page?.seoMetadata?.title
    ? page?.seoMetadata.title + ' | ' + t('seo.websiteTitle')
    : t('seo.websiteTitle');

  // all possible properties are listed here: https://github.com/harlan-zw/zhead/blob/main/src/metaFlat.ts
  useSeoMeta({
    title: () => pageTitle,
    twitterTitle: () => page?.seoMetadata?.title || t('seo.ogTitle'),
    twitterDescription: () => page?.seoMetadata?.description || t('seo.description'),
    ogTitle: () => page?.seoMetadata?.title || t('seo.ogTitle'),
    description: () => page?.seoMetadata?.description || t('seo.description'),
    ogDescription: () => page?.seoMetadata?.description || t('seo.description'),
    ogImage: () => ogImage,
    ogType: () => 'website',
    twitterCard: 'summary_large_image'
  });
}
