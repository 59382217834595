import { SanityPage } from '~/types';

export const useFetchPreviewPage = async (): Promise<SanityPage | null> => {
  const sanity = useSanity();
  const {
    query: { documentId }
  } = useRoute();
  const query = `*[_id=="${documentId}"][0]`;
  console.log(query);
  const { data } = await useAsyncData<SanityPage>(documentId as string, () => sanity.fetch(query));

  return data.value;
};
