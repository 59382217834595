<template>
  <div>
    <div
      id="scroll-main"
      class="flex flex-col w-full min-h-screen relative"
      :class="backgroundColor"
    >
      <Header :page="page" />
      <div class="container mt-6">
        <TitleWithBackButton
          :title="page.detailPageSettings.detailPageTitle"
          :back-url="backUrl"
          :back-label="$text('package-back-button-label')"
        />
      </div>

      <div v-if="page.content">
        <component
          v-for="(section, index) in page.content?.sections"
          :is="renderSection(section)"
          :key="index"
          :data="section"
        />
      </div>
      <div
        class="container"
        v-else
      >
        <p class="my-8">This page is empty. Please, add a section in Sanity</p>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';

const { page } = defineProps({
  page: Object
});

const { backgroundColorClass } = useSiteSettings();
const backgroundColor = computed(() => backgroundColorClass(page));

const settingsStore = useSettingsStore();
const { $fullpath, $text } = useSiteSettings();
const { siteParts } = storeToRefs(settingsStore);
const backUrl = $fullpath(siteParts.value.general.packagePage._ref) || '/';

const renderSection = (section) => {
  const TextBlock = resolveComponent('TextBlock');
  const Faq = resolveComponent('Faq');
  const ImageWithCaption = resolveComponent('ImageWithCaption');
  const VideoWithCaption = resolveComponent('VideoWithCaption');
  const Slideshow = resolveComponent('Slideshow');
  const Form = resolveComponent('Form');
  const Teaser = resolveComponent('Teaser');
  const Carousel = resolveComponent('Carousel');
  const Sticky = resolveComponent('Sticky');
  const VideoHero = resolveComponent('VideoHero');
  const Events = resolveComponent('Events');
  const AccordionTeaser = resolveComponent('AccordionTeaser');
  const Buttons = resolveComponent('Buttons');
  const CallToAction = resolveComponent('CallToAction');
  const Contact = resolveComponent('Contact');
  const CustomerReview = resolveComponent('CustomerReview');
  const DetailIntro = resolveComponent('DetailIntro');
  const Illustration = resolveComponent('Illustration');
  const Image2Columns = resolveComponent('Image2Columns');
  const LeadWithImage = resolveComponent('LeadWithImage');
  const MediaList = resolveComponent('MediaList');
  const MenuDownload = resolveComponent('MenuDownload');
  const Multimedia = resolveComponent('Multimedia');
  const Overview = resolveComponent('Overview');
  const Quote = resolveComponent('Quote');
  const RoomFeatures = resolveComponent('RoomFeatures');
  const Statement = resolveComponent('Statement');
  const TextImages2Columns = resolveComponent('TextImages2Columns');
  const Title2columns = resolveComponent('Title2columns');
  const IFrameVideo = resolveComponent('IFrameVideo');
  const Employees = resolveComponent('Employees');
  const DownloadList = resolveComponent('DownloadList');
  const GuestBook = resolveComponent('GuestBook');
  const Webcam = resolveComponent('Webcam');
  const Newsletter = resolveComponent('Newsletter');
  const List2Columns = resolveComponent('List2Columns');

  const isTextBlockSection = section._type === 'textBlock';
  const isIFrameVideoSection = section._type === 'iframeVideo';
  const isFaqSection = section._type === 'faq';
  const isImageWithCaptionSection = section._type === 'imageWithCaption';
  const isVideoWithCaptionSection = section._type === 'videoWithCaption';
  const isSlideshowSection = section._type === 'slideshow';
  const isFormSection = section._type === 'form';
  const isEventsSection = section._type === 'events';
  const isTeaserSection = section._type === 'teaser';
  const isCarouselSection = section._type === 'carousel';
  const isStickySection = section._type === 'sticky';
  const isVideoHeroSection = section._type === 'videoHero';
  const isAccordionTeaserSection = section._type === 'accordionTeaser';
  const isButtonsSection = section._type === 'buttons';
  const isCallToActionSection = section._type === 'callToAction';
  const isContactSection = section._type === 'contact';
  const isCustomerReviewSection = section._type === 'customerReview';
  const isDetailIntroSection = section._type === 'detailIntro';
  const isEmployeesSection = section._type === 'employees';
  const isIllustrationSection = section._type === 'illustration';
  const isImage2ColumnsSection = section._type === 'image2Columns';
  const isLeadWithImageSection = section._type === 'leadWithImage';
  const isMediaListSection = section._type === 'mediaList';
  const isMenuDownloadSection = section._type === 'menuDownload';
  const isMultimediaSection = section._type === 'multimedia';
  const isOverviewSection = section._type === 'overview';
  const isQuoteSection = section._type === 'quote';
  const isRoomFeaturesSection = section._type === 'roomFeatures';
  const isStatementSection = section._type === 'statement';
  const isTextImages2ColumnsSection = section._type === 'textImages2Columns';
  const isTitle2columnsSection = section._type === 'title2columns';
  const isDownloadListSection = section._type === 'downloadList';
  const isGuestBookSection = section._type === 'guestBook';
  const isWebcamSection = section._type === 'webcam';
  const isNewsletterSection = section._type === 'newsletter';
  const isList2ColumnsSection = section._type === 'list2Columns';

  let renderedSection;
  switch (true) {
    case isTextBlockSection:
      renderedSection = TextBlock;
      break;
    case isIFrameVideoSection:
      renderedSection = IFrameVideo;
      break;
    case isFaqSection:
      renderedSection = Faq;
      break;
    case isImageWithCaptionSection:
      renderedSection = ImageWithCaption;
      break;
    case isSlideshowSection:
      renderedSection = Slideshow;
      break;
    case isVideoHeroSection:
      renderedSection = VideoHero;
      break;
    case isVideoWithCaptionSection:
      renderedSection = VideoWithCaption;
      break;
    case isFormSection:
      renderedSection = Form;
      break;
    case isTeaserSection:
      renderedSection = Teaser;
      break;
    case isCarouselSection:
      renderedSection = Carousel;
      break;
    case isStickySection:
      renderedSection = Sticky;
      break;
    case isEventsSection:
      renderedSection = Events;
      break;
    case isAccordionTeaserSection:
      renderedSection = AccordionTeaser;
      break;
    case isButtonsSection:
      renderedSection = Buttons;
      break;
    case isCallToActionSection:
      renderedSection = CallToAction;
      break;
    case isContactSection:
      renderedSection = Contact;
      break;
    case isCustomerReviewSection:
      renderedSection = CustomerReview;
      break;
    case isDetailIntroSection:
      renderedSection = DetailIntro;
      break;
    case isEmployeesSection:
      renderedSection = Employees;
      break;
    case isIllustrationSection:
      renderedSection = Illustration;
      break;
    case isImage2ColumnsSection:
      renderedSection = Image2Columns;
      break;
    case isLeadWithImageSection:
      renderedSection = LeadWithImage;
      break;
    case isMediaListSection:
      renderedSection = MediaList;
      break;
    case isMenuDownloadSection:
      renderedSection = MenuDownload;
      break;
    case isMultimediaSection:
      renderedSection = Multimedia;
      break;
    case isOverviewSection:
      renderedSection = Overview;
      break;
    case isQuoteSection:
      renderedSection = Quote;
      break;
    case isRoomFeaturesSection:
      renderedSection = RoomFeatures;
      break;
    case isStatementSection:
      renderedSection = Statement;
      break;
    case isTextImages2ColumnsSection:
      renderedSection = TextImages2Columns;
      break;
    case isTitle2columnsSection:
      renderedSection = Title2columns;
      break;
    case isDownloadListSection:
      renderedSection = DownloadList;
      break;
    case isGuestBookSection:
      renderedSection = GuestBook;
      break;
    case isWebcamSection:
      renderedSection = Webcam;
      break;
    case isNewsletterSection:
      renderedSection = Newsletter;
      break;
    case isList2ColumnsSection:
      renderedSection = List2Columns;
      break;
    default:
      renderedSection = null;
      break;
  }

  return renderedSection;
};
</script>

<style></style>
