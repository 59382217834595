import { SanityPage } from '~/types';
import getPageQuery from '~/queries/getPageQuery';

export const useFetchPage = async (): Promise<SanityPage | null> => {
  const sanity = useSanity();
  const route = useRoute();
  const i18n = useI18n();
  const query = getPageQuery(route.path, i18n.locale.value);
  const { data } = await useAsyncData<SanityPage>(route.fullPath, () => sanity.fetch(query));

  return data.value;
};
