<template>
  <div class="mb-6 fade-in-top-slow">
    <h1>{{ props.title }}</h1>
    <button
      class="flex mt-4 cursor-pointer w-fit group"
      @click="navigateBack"
    >
      <!-- <SVGLoader
        name="backArrow"
        
      /> -->
      <svg
        width="38"
        height="12"
        viewBox="0 0 28 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="group-hover:-translate-x-[3px] transition-all duration-200"
          d="M2.34823 6.05664L26.3965 6.05664"
          stroke="#000"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.34823 6.05664L26.3965 6.05664"
          stroke="#000"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          class="group-hover:-translate-x-[3px] transition-all duration-200"
          d="M6 1L0.999999 6L6 11"
          stroke="#000"
          stroke-width="0.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span class="uppercase ml-2 back-label">
        {{ props.backLabel || 'back' }}
      </span>
    </button>
  </div>
</template>

<script lang="ts" setup>
interface TitleWithBackButtonProps {
  title: string;
  backLabel?: string;
  backUrl?: string;
}

const router = useRouter();

const navigateBack = async () => {
  if (props.backUrl) {
    await navigateTo(`${props.backUrl}`);
  } else {
    router.back();
  }
};

const props = defineProps<TitleWithBackButtonProps>();
</script>

<style lang="postcss">
.back-label {
  font-size: fluid(10, 12, 640, 1792);
  @apply text-black;
}
</style>
