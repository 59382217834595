export default function useBaseMetatags(page) {
  const baseMeta = [];
  // Generate Robots meta tag
  const index = page?.value?.seoMetadata?.noIndex ? 'noindex, ' : 'index, ';
  const follow = page?.value?.seoMetadata?.noFollow ? 'nofollow' : 'follow';

  baseMeta.push({
    hid: 'robots',
    name: 'robots',
    content: `${index}${follow}`
  });

  useHead({
    meta: [...baseMeta]
  });
}
