import imageUrlBuilder from '@sanity/image-url';
import { SanityPage } from '~/types';

export default function useSEOMetatags(page: SanityPage | null) {
  const { t } = useI18n();
  if (!page) return;

  // GET OG IMAGE
  let config = useRuntimeConfig();
  const builder = imageUrlBuilder(config.public.sanity);
  const ogImage = page?.seoMetadata?.image
    ? builder.image(page?.seoMetadata?.image).url()
    : `${config.public.baseURL}ogimage.jpg`;

  const pageTitle = page?.seoMetadata?.title ? page?.seoMetadata.title : t('seo.websiteTitle');

  const description = page?.seoMetadata?.description || t('seo.description');
  // all possible properties are listed here: https://github.com/harlan-zw/zhead/blob/main/src/metaFlat.ts
  useSeoMeta({
    title: () => pageTitle,
    twitterTitle: () => pageTitle || t('seo.ogTitle'),
    twitterDescription: () => description,
    ogTitle: () => pageTitle || t('seo.ogTitle'),
    description: () => description,
    ogDescription: () => description,
    ogImage: () => ogImage,
    ogType: () => 'website',
    twitterCard: 'summary_large_image'
  });
}
