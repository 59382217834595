import { SanityPage } from '~/types';

export default function useSEOAlternatesLinks(page: SanityPage | null) {
  let translations = page?.translations;
  if (!translations) return;

  let config = useRuntimeConfig();

  const appLocales = config.public.locales;
  const defaultLocale = config.public.defaultLocale;
  const baseUrl = config.public.baseURL;

  // Note: There might be the case where pages are not translated.
  // So the backend response will set for ex: { de: {...}, fr: NULL}
  const availableTranslations = appLocales
    ? appLocales.filter((locale) => translations[locale.code] !== null)
    : [];

  const links = [];
  availableTranslations.forEach((locale) => {
    if (!translations[locale.code]) return;

    const { url, isHome = false } = translations[locale.code] || '';
    const fullPath = url?.fullPath || '';
    const isDefaultLocale = locale.code === defaultLocale;
    // Note: if translation don't exist, then we avoid to have URLs like
    // https://localhost/undefined

    let href;
    // Business Rule: base locale don't have prefix. Ex: http://localhost/locale
    if (isDefaultLocale) {
      href = isHome ? `${baseUrl}` : `${baseUrl}${fullPath}`;
      links.push({ rel: 'alternate', hreflang: locale.iso, href });
      links.push({ rel: 'alternate', hreflang: 'x-default', href });
    } else {
      href = isHome ? `${baseUrl}${locale.code}` : `${baseUrl}${locale.code}/${fullPath}`;
      links.push({ rel: 'alternate', hreflang: locale.iso, href });
    }
  });

  const isHome = page?.isHome || false;
  const url = page?.url?.fullPath || '';
  const lang = page?.__i18n_lang || '';

  links.push({
    rel: 'canonical',
    href: `${baseUrl}${isHome ? '' : `${lang}/${url}`}`
  });

  // Add hreflang urls to head
  useHead({
    meta: [...links]
  });
}
