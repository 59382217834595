<template>
  <div>
    <div
      class="flex flex-col w-full min-h-screen relative"
      :class="backgroundColor"
    >
      <!-- Status bar -->
      <Transition
        mode="out-in"
        appear
        name="slide"
      >
        <div
          v-if="showStatusBar || !userHasClosedStatusBar"
          class="w-full flex justify-between bg-greenDark container py-4 fade-in"
        >
          <div>
            <p class="text-white font-bold mb-1">{{ $text('status-bar-title') }}</p>
            <p class="text-white">{{ $text('status-bar-paragraph') }}</p>
          </div>
          <div
            class="cursor-pointer"
            @click="closeStatusBar"
          >
            <SVGLoader
              class="w-6 h-6"
              name="close"
            />
          </div>
        </div>
      </Transition>

      <Header :page="page" />
      <div v-if="page && page.content?.sections.length > 0">
        <component
          v-for="(section, index) in page.content.sections"
          :is="renderSection(section)"
          :key="index"
          :data="section"
        />
      </div>
      <div
        class="container"
        v-else
      >
        <p class="my-8">This page is empty. Please, add a section in Sanity</p>
      </div>

      <StickyCallToActions />

      <Footer />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/useSettingsStore';
const { page } = defineProps({
  page: Object
});

const { siteParts } = storeToRefs(useSettingsStore());
const { $fullpath, $text } = useSiteSettings();
const cookieStatusBar = useCookie('status-bar-closed');
const userHasClosedStatusBar = ref<boolean>(true);
const showStatusBar = computed(() => {
  // Regardless the cookie, if admin deactivate the status bar, we don't show it
  if (siteParts.value?.header.statusBar.isActive === false) {
    return false;
  }
  // otherwise, we check the cookie
  if (cookieStatusBar.value) {
    return false;
  }
  // elseway, we return the value stored in the website settings
  return siteParts.value?.header.statusBar.isActive;
});

const closeStatusBar = () => {
  userHasClosedStatusBar.value = true;
  cookieStatusBar.value = 'true';
};

const backgroundColor = computed(() => {
  // Note: normal pages don't have a detailPageSettings object
  if (page?.hasBackground) {
    if (page.pageBackgroundColor.label == 'Gold') {
      return 'bg-secondary';
    }
    if (page.pageBackgroundColor.label == 'Rose') {
      return 'bg-rose';
    }
    if (page.pageBackgroundColor.label == 'Green') {
      return 'bg-green';
    }
  }

  return 'bg-white';
});

const renderSection = (section: any) => {
  const TextBlock = resolveComponent('TextBlock');
  const ImageWithCaption = resolveComponent('ImageWithCaption');
  const VideoWithCaption = resolveComponent('VideoWithCaption');
  const Slideshow = resolveComponent('Slideshow');
  const Form = resolveComponent('Form');
  const Teaser = resolveComponent('Teaser');
  const Carousel = resolveComponent('Carousel');
  const Sticky = resolveComponent('Sticky');
  const VideoHero = resolveComponent('VideoHero');
  const Events = resolveComponent('Events');
  const AccordionTeaser = resolveComponent('AccordionTeaser');
  const Buttons = resolveComponent('Buttons');
  const BookingTool = resolveComponent('BookingTool');
  const CallToAction = resolveComponent('CallToAction');
  const Contact = resolveComponent('Contact');
  const CustomerReview = resolveComponent('CustomerReview');
  const DetailIntro = resolveComponent('DetailIntro');
  const Illustration = resolveComponent('Illustration');
  const Image2Columns = resolveComponent('Image2Columns');
  const LeadWithImage = resolveComponent('LeadWithImage');
  const PressMediaList = resolveComponent('PressMediaList');
  const MenuDownload = resolveComponent('MenuDownload');
  const Multimedia = resolveComponent('Multimedia');
  const Overview = resolveComponent('Overview');
  const Quote = resolveComponent('Quote');
  const RoomFeatures = resolveComponent('RoomFeatures');
  const Statement = resolveComponent('Statement');
  const TextImages2Columns = resolveComponent('TextImages2Columns');
  const Title2columns = resolveComponent('Title2columns');
  const IFrameVideo = resolveComponent('IFrameVideo');
  const Employees = resolveComponent('Employees');
  const DownloadList = resolveComponent('DownloadList');
  const GuestBook = resolveComponent('GuestBook');
  const Webcam = resolveComponent('Webcam');
  const Newsletter = resolveComponent('Newsletter');
  const List2Columns = resolveComponent('List2Columns');
  const Accordion = resolveComponent('Accordion');
  const MediaCorner = resolveComponent('MediaCorner');
  const CookieData = resolveComponent('CookieData');

  const isTextBlockSection = section._type === 'textBlock';
  const isIFrameVideoSection = section._type === 'iframeVideo';
  const isImageWithCaptionSection = section._type === 'imageWithCaption';
  const isVideoWithCaptionSection = section._type === 'videoWithCaption';
  const isSlideshowSection = section._type === 'slideshow';
  const isFormSection = section._type === 'form';
  const isEventsSection = section._type === 'events';
  const isTeaserSection = section._type === 'teaser';
  const isCarouselSection = section._type === 'carousel';
  const isStickySection = section._type === 'sticky';
  const isVideoHeroSection = section._type === 'videoHero';
  const isAccordionTeaserSection = section._type === 'accordionTeaser';
  const isButtonsSection = section._type === 'buttons';
  const isCallToActionSection = section._type === 'callToAction';
  const isContactSection = section._type === 'contact';
  const isCustomerReviewSection = section._type === 'customerReview';
  const isDetailIntroSection = section._type === 'detailIntro';
  const isEmployeesSection = section._type === 'employees';
  const isIllustrationSection = section._type === 'illustration';
  const isImage2ColumnsSection = section._type === 'image2Columns';
  const isLeadWithImageSection = section._type === 'leadWithImage';
  const isPressMediaListSection = section._type === 'pressMediaList';
  const isMenuDownloadSection = section._type === 'menuDownload';
  const isMultimediaSection = section._type === 'multimedia';
  const isOverviewSection = section._type === 'overview';
  const isQuoteSection = section._type === 'quote';
  const isRoomFeaturesSection = section._type === 'roomFeatures';
  const isStatementSection = section._type === 'statement';
  const isTextImages2ColumnsSection = section._type === 'textImages2Columns';
  const isTitle2columnsSection = section._type === 'title2columns';
  const isDownloadListSection = section._type === 'downloadList';
  const isGuestBookSection = section._type === 'guestBook';
  const isWebcamSection = section._type === 'webcam';
  const isNewsletterSection = section._type === 'newsletter';
  const isList2ColumnsSection = section._type === 'list2Columns';
  const isAccordionSection = section._type === 'accordion';
  const isBookingTool = section._type === 'bookingTool';
  const isMediaCornerSection = section._type === 'mediaCorner';
  const isCookieData = section._type === 'cookieData';

  let renderedSection;
  switch (true) {
    case isTextBlockSection:
      renderedSection = TextBlock;
      break;
    case isBookingTool:
      renderedSection = BookingTool;
      break;
    case isIFrameVideoSection:
      renderedSection = IFrameVideo;
      break;
    case isImageWithCaptionSection:
      renderedSection = ImageWithCaption;
      break;
    case isSlideshowSection:
      renderedSection = Slideshow;
      break;
    case isVideoHeroSection:
      renderedSection = VideoHero;
      break;
    case isVideoWithCaptionSection:
      renderedSection = VideoWithCaption;
      break;
    case isFormSection:
      renderedSection = Form;
      break;
    case isTeaserSection:
      renderedSection = Teaser;
      break;
    case isCarouselSection:
      renderedSection = Carousel;
      break;
    case isStickySection:
      renderedSection = Sticky;
      break;
    case isEventsSection:
      renderedSection = Events;
      break;
    case isAccordionTeaserSection:
      renderedSection = AccordionTeaser;
      break;
    case isButtonsSection:
      renderedSection = Buttons;
      break;
    case isCallToActionSection:
      renderedSection = CallToAction;
      break;
    case isContactSection:
      renderedSection = Contact;
      break;
    case isCustomerReviewSection:
      renderedSection = CustomerReview;
      break;
    case isDetailIntroSection:
      renderedSection = DetailIntro;
      break;
    case isEmployeesSection:
      renderedSection = Employees;
      break;
    case isIllustrationSection:
      renderedSection = Illustration;
      break;
    case isImage2ColumnsSection:
      renderedSection = Image2Columns;
      break;
    case isLeadWithImageSection:
      renderedSection = LeadWithImage;
      break;
    case isPressMediaListSection:
      renderedSection = PressMediaList;
      break;
    case isMenuDownloadSection:
      renderedSection = MenuDownload;
      break;
    case isMultimediaSection:
      renderedSection = Multimedia;
      break;
    case isOverviewSection:
      renderedSection = Overview;
      break;
    case isQuoteSection:
      renderedSection = Quote;
      break;
    case isRoomFeaturesSection:
      renderedSection = RoomFeatures;
      break;
    case isStatementSection:
      renderedSection = Statement;
      break;
    case isTextImages2ColumnsSection:
      renderedSection = TextImages2Columns;
      break;
    case isTitle2columnsSection:
      renderedSection = Title2columns;
      break;
    case isDownloadListSection:
      renderedSection = DownloadList;
      break;
    case isGuestBookSection:
      renderedSection = GuestBook;
      break;
    case isWebcamSection:
      renderedSection = Webcam;
      break;
    case isNewsletterSection:
      renderedSection = Newsletter;
      break;
    case isList2ColumnsSection:
      renderedSection = List2Columns;
      break;
    case isAccordionSection:
      renderedSection = Accordion;
      break;
    case isMediaCornerSection:
      renderedSection = MediaCorner;
      break;
    case isCookieData:
      renderedSection = CookieData;
      break;
    default:
      renderedSection = null;
      break;
  }

  return renderedSection;
};
</script>

<style scoped>
.slide-leave-active {
  animation: slide-fade-out2 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes slide-fade-out2 {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}
</style>
