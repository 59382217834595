export default function usePrefetchMetatags() {
  let config = useRuntimeConfig();
  const baseUrl = config.public.baseURL;
  const projectId = process.env.SANITY_PROJECT_ID;

  const prefetchTags = [
    { hid: "preconnect", rel: "preconnect", href: baseUrl },
    { hid: "dns-prefetch", rel: "dns-prefetch", href: baseUrl },
  ];

  if (projectId) {
    prefetchTags.push(
      {
        hid: "preconnect-sanity",
        rel: "preconnect",
        href: `https://${projectId}.apicdn.sanity.io`,
      },
      {
        hid: "dns-prefetch-sanity",
        rel: "dns-prefetch",
        href: `https://${projectId}.apicdn.sanity.io`,
      }
    );
  }

  useHead({
    meta: [...prefetchTags],
  });

  return prefetchTags;
}
